<template>
  <area-cascader v-model="area" placeholder="请选择区域" type="all" :level="2" :data="pcaa"></area-cascader>
</template>

<script>
import { pca, pcaa } from 'area-data-vue'
import 'area-linkage-vue/dist/index.css'

export default {
  name: "y_choose_area",
  props:{
    val:{
      type:Object,
      default(){
        return{
          province_code:"",
          province_title:"",
          city_code:"",
          city_title:"",
          county_code:"",
          county_title:"",
          town_code:"",
          town_title:"",
        }
      }
    }
  },
  data(){
    return{
      pca:pca,
      pcaa:pcaa,
      area:[],
    }
  },
  watch:{
    val(e){
      this.formObj2area(e)
    },
    area(e){
      if (typeof e[0] === 'string'){
        return;
      }
      this.area2formObj(e);
    }
  },
  methods:{
    formObj2area(form){
      this.area = [{},{},{},{}];
      this.area[0] = form.province_title
      this.area[1] = form.city_title
      this.area[2] = form.county_title
      this.area[3] = form.town_title
    },
    area2formObj(area){
      let obj = {}
      obj.province_code = Object.keys(area[0]).pop();
      obj.province_title = area[0][obj.province_code];
      obj.city_code = Object.keys(area[1]).pop();
      obj.city_title = area[1][obj.city_code];
      obj.county_code = Object.keys(area[2]).pop();
      obj.county_title = area[2][obj.county_code];
      obj.town_code = Object.keys(area[3]).pop();
      obj.town_title = area[3][obj.town_code];
      this.$emit("change",obj);
    }
  }
}
</script>

<style>
.area-select.large{
  width: 100%;
  line-height: 20px;
}
.cascader-menu-list .cascader-menu-option.selected {
  background-color: #4ca2ff;
  color: #ffffff;
  font-weight: 700;
}
</style>
<style scoped>

</style>