<template>
  <el-select v-model="value" :loading="loading">
    <el-option v-for="item in options" :key="item.value" :label="item.title" :value="item.id"></el-option>
  </el-select>
</template>

<script>
export default {
  name: "y_choose_industry",
  props:{
    industry_id: {
      type:Number,
      default:0
    }
  },
  model:{
    prop:"industry_id",
    event:"change"
  },
  watch:{
    value(e){
      this.$emit("change",e)
    },
    industry_id(e){
      if(this.industry_id > 0){
        this.value = e;
        // this.$api.industry.one({id:this.industry_id}).then(res=>{
        //   this.value = e;
        //   this.options = [{id:e,title:res.title}];
        // })
      }
    }
  },
  data(){
    return{
      value:"",
      options:[],
      loading: false,
    }
  },
  mounted() {
    // console.log(this.industry_id)
    this.loadOpt();
  },
  methods:{
    loadOpt(){
      this.$api.industry.all().then(res=>{
        this.options = res;
      })
    },
    remoteMethod(query) {
      if(query === ''){
        return;
      }
      this.loading = true;
      this.$api.industry.search({title:query}).then(res=>{
        this.loading = false;
        this.options = res;
      })
    },
  }
}
</script>

<style scoped>

</style>